import { faLocationDot, faSearch, faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, useCallback, useRef, useState } from 'react';

import { ClearFilterButton } from '@/components/ClearFilterButton';
import { GOOGLE_MAP_API_KEY } from '@/map/lib/constants';
import { useTournaments } from '@/tournament/lib/context';
import { GooglePlaceResult } from '@/tournament/lib/google.type';

interface MobileLocationSearchInputProps {
	onLocationClick?: (prediction: google.maps.places.AutocompletePrediction) => void;
}

export const MobileLocationSearchInput = ({ onLocationClick }: MobileLocationSearchInputProps) => {
	const [autocompletePredictions, setAutocompletePredictions] = useState<google.maps.places.AutocompletePrediction[] | null>(null);
	const [apLoading, setApLoading] = useState(false);
	const { setPlace, setPlaceKeyword, mapArea, placeKeyword } = useTournaments();

	const inputRef = useRef<HTMLInputElement>(null);
	const inputRefDebounce = useRef<NodeJS.Timeout | null>(null);

	const onApClick = async (placeId: string) => {
		const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${GOOGLE_MAP_API_KEY}`);
		const data: { results: GooglePlaceResult[] } = await response.json();
		if (data.results[0]) {
			setPlace(data.results[0]);
		}
	};

	const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setPlaceKeyword(e.target.value);
		if (inputRefDebounce.current) clearTimeout(inputRefDebounce.current);

		inputRefDebounce.current = setTimeout(() => {
			if (e.target.value.length > 0) {
				setApLoading(true);
				const service = new google.maps.places.AutocompleteService();
				service.getPlacePredictions(
					{
						input: e.target.value,
						types: ['geocode']
					},
					(results, status) => {
						if (status === google.maps.places.PlacesServiceStatus.OK) {
							setAutocompletePredictions(results);
							setApLoading(false);
						}
					}
				);
			} else if (e.target.value.length === 0) {
				setAutocompletePredictions(null);
				setApLoading(false);
			}
		}, 300);
	}, []);
	return (
		<div className="w-full">
			<div className="relative">
				<FontAwesomeIcon icon={faSearch} className="absolute left-4 top-1/2 -mt-2 size-4" />
				<input
					type="text"
					className="h-14 w-full rounded-xl bg-[#F7F7F7] pl-10 text-base focus:outline-none md:text-sm"
					placeholder="Search locations"
					onChange={onInputChange}
					autoFocus
					value={!open && mapArea ? 'Map area' : placeKeyword || ''}
					ref={inputRef}
				/>
				{placeKeyword && (
					<ClearFilterButton
						className="bg-gray-300 hover:bg-gray-400"
						onClick={(e) => {
							e.stopPropagation();
							setPlace(null);
							inputRef.current?.focus();
							setAutocompletePredictions([]);
						}}
					/>
				)}
			</div>
			{apLoading ? (
				<div className="flex items-center justify-center py-6">
					<FontAwesomeIcon icon={faSpinnerThird} spin size="lg" />
				</div>
			) : (
				<>
					{autocompletePredictions ? (
						<div className="mt-2">
							{autocompletePredictions.map((item) => {
								return (
									<div
										key={item.place_id}
										className="flex w-full cursor-pointer select-none flex-row items-center rounded-lg p-2 px-0 hover:bg-gray-100"
										onClick={() => {
											onApClick(item.place_id);
											if (onLocationClick) {
												onLocationClick(item);
											}
										}}
									>
										<span className="flex size-12 shrink-0 items-center justify-center rounded-xl bg-gray-200">
											<FontAwesomeIcon icon={faLocationDot} size="lg" />
										</span>
										<span className="ml-4 flex-1 text-base font-normal">{item.description}</span>
									</div>
								);
							})}
						</div>
					) : null}
				</>
			)}
		</div>
	);
};
