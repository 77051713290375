import { createContext, useContext } from 'react';

import { LatLng } from '@/tournament/lib/context';

export type MapDisplayState = string; // 'display' | 'full' | 'hidden';

export interface MapBounds {
	ne_lat: number;
	ne_lng: number;
	sw_lat: number;
	sw_lng: number;
}

export interface MapState {
	center?: {
		lat: number;
		lng: number;
	};
	isLoaded: boolean;
	map: google.maps.Map | null;
	mapDisplay: MapDisplayState;
	programmaticZoom: boolean;
	showAll: boolean; // when this is set, it will hide map fully and ignore any other states
	viewport?: {
		width: number;
		height: number;
	};
	zoomLevel?: number;
}

export const initialMapState: MapState = {
	center: { lat: 39.67917984404462, lng: -95.8065000871444 },
	isLoaded: false,
	map: null,
	mapDisplay: 'display',
	programmaticZoom: false,
	showAll: false,
	zoomLevel: 3.6
};

interface MapContextInterface extends MapState {
	moveCamera: () => void;
	onLoad: (map: google.maps.Map) => void;
	onUnmount: () => void;
	setMapDisplay: (display: MapDisplayState) => void;
	setMapPosition: (center: LatLng, zoomLevel: number, programmaticZoom: boolean) => void;
	setProgrammaticZoom: (programmaticZoom: boolean) => void;
	setShowAll: (showAll: boolean, mapDisplay?: MapDisplayState) => void;
	setViewPort: (width: number, height: number) => void;
	resetToInitialState: (state: Partial<MapState>) => void;
}

const stub = (): never => {
	throw new Error('You likely forgot to wrap your app into <MapProvider> :|');
};

const initialContext: MapContextInterface = {
	...initialMapState,
	moveCamera: stub,
	onLoad: stub,
	onUnmount: stub,
	setMapDisplay: stub,
	setMapPosition: stub,
	setProgrammaticZoom: stub,
	setShowAll: stub,
	setViewPort: stub,
	resetToInitialState: stub
};

export const MapContext = createContext<MapContextInterface>(initialContext);

export const useMap = (context = MapContext) => {
	return useContext(context) as MapContextInterface;
};
