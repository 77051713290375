export interface TriggerURLResponse {
	status: number;
}

export async function triggerURL(URL: string): Promise<TriggerURLResponse> {
	try {
		const response = await fetch(URL, {
			method: 'GET',
			mode: 'no-cors'
		});

		if (response.ok) {
			return {
				status: 200
			};
		}

		return {
			status: response.status
		};
	} catch (e) {
		if (e instanceof Error) {
			console.log(e.message);
		} else {
			console.log(e);
		}

		return {
			status: 404
		};
	}
}
