import { SessionData } from '@/lib/sessionsv2/types';
import { MapBounds } from '@/map/lib/context';
import { LatLng, TournamentsState } from '@/tournament/lib/context';
import { IpApi } from '@/types/ip-api';

export const initialState: TournamentsState = {
	bounds: null,
	center: null,
	data: null,
	dates: null,
	clubId: null,
	keyword: null,
	limit: 25,
	loading: true,
	mapArea: false,
	pastEvents: false,
	place: null,
	managing: false,
	featured: false,
	myTournaments: false,
	userLocationFetch: false,
	nowPlaying: false,
	mtPast: false,
	nowRegistering: false,
	registeredFor: false,
	selectedTournamentFilter: null,
	placeKeyword: null,
	page: 1,
	searchType: null,
	zoomLevel: null,
	selectedPartner: null
};

export function getValueByKey(searchParams: { [key: string]: string | string[] | undefined }, key: string) {
	const value = searchParams[key];

	if (Array.isArray(value)) {
		// Handle the case where the value is an array of strings
		return null;
	} else if (typeof value === 'string') {
		// Handle the case where the value is a single string
		return value;
	} else {
		// Handle the case where the value is undefined
		return null;
	}
}

export const composeCenter = (center_lat: string | null, center_lng: string | null): LatLng | null => {
	if (center_lat && center_lng) {
		return { lat: parseFloat(center_lat), lng: parseFloat(center_lng) };
	}

	return null;
};

export const composeBounds = (ne_lat: string | null, ne_lng: string | null, sw_lat: string | null, sw_lng: string | null): MapBounds | null => {
	if (ne_lat && ne_lng && sw_lat && sw_lng) {
		return { ne_lat: parseFloat(ne_lat), ne_lng: parseFloat(ne_lng), sw_lat: parseFloat(sw_lat), sw_lng: parseFloat(sw_lng) };
	}

	return null;
};

export interface ComposeStateProps {
	searchParams: { [key: string]: string | string[] | undefined };
	ipApi: IpApi | null;
}

export const clubIdsMapping = new Map<string, string>([
	['80f17003-cd92-4fc4-84e4-06d38e096042', 'sanction_ppa'],
	['f6f5296b-b9a6-4d25-a295-29f461de56d2', 'sanction_mlp'],
	['a484ec40-ff2f-4a27-a831-25ac6408b64a', 'sanction_upa'],
	['2a56413a-0012-4d1d-9792-c54138ad1b4e', 'sanction_pco'],
	['2fb32e18-580c-433a-8d7d-4ad032c45b51', 'sanction_paa'],
	['16ce92c6-eeb5-4b68-9b9c-87bf57ceb855', 'sanction_ssipa'],
	['51e3befa-85f0-46c5-b776-7a7d5241fc37', 'sanction_app'],
	['43088f68-1f44-450f-8c7f-6f7ab3a94581', 'sanction_gpa'],
	['dbe502ad-9463-48a5-9808-23040361c613', 'sanction_usapa'],
	['b3e1a5e9-1d0e-4a51-aa8e-70455f428d61', 'sanction_pig']
]);

export interface ComposeStateClientProps extends ComposeStateProps {
	session?: SessionData;
}
