import { useEffect, useState } from 'react';

const imageCache: { [key: string]: HTMLImageElement } = {};

const preloadImage = (src: string): Promise<HTMLImageElement> => {
	return new Promise((resolve, reject) => {
		if (imageCache[src]) {
			resolve(imageCache[src]);
		} else {
			const img = new Image();
			img.src = src;
			img.onload = () => {
				imageCache[src] = img;
				resolve(img);
			};
			img.onerror = (error) => reject(error);
		}
	});
};

const usePreloadImages = (images: string[]): boolean => {
	const [loaded, setLoaded] = useState<boolean>(false);

	useEffect(() => {
		let isMounted = true;

		const preloadAll = async () => {
			await Promise.all(images.map((src) => preloadImage(src)));
			if (isMounted) {
				setLoaded(true);
			}
		};

		preloadAll();

		return () => {
			isMounted = false;
		};
	}, [images]);

	return loaded;
};

export default usePreloadImages;
