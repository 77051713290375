'use client';

import { faLocationDot, faMapMarkerAlt, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UnstyledButton } from '@pickleballinc/ui/components/tournaments-app//unstyled-button';
import clsx from 'clsx';
import { useRouter } from 'next/navigation';
import React from 'react';

import { regions } from '@/components/regions';
import { useSession } from '@/lib/SessionProvider';
import { GOOGLE_MAP_API_KEY } from '@/map/lib/constants';
import { useMap } from '@/map/lib/context';
import { useLocation } from '@/providers/location';
import { composeInitialStateFromClient } from '@/tournament/lib/compose-initial-state-from-client';
import { GooglePlaceResult } from '@/tournament/lib/google.type';

import { useTournaments } from '../tournament/lib/context';

interface LocationPopoverProps {
	isVisible: boolean;
	autocompletePredictions: google.maps.places.AutocompletePrediction[] | null;
	apLoading: boolean;
	onLocationClick?: () => void;
}

export const LocationPopover = ({ isVisible, autocompletePredictions, apLoading, onLocationClick }: LocationPopoverProps) => {
	const { setPlace, setPlaceKeyword, setKeyword, resetToInitialState: resetToursToInitialState } = useTournaments();
	const { location } = useLocation();
	const { resetToInitialState } = useMap();
	const session = useSession();

	const classes = clsx('absolute left-0 top-full mt-2 w-1/2 min-w-[34rem] overflow-y-auto overflow-x-hidden rounded-[32px] bg-white', {
		'opacity-0 invisible pointer-events-none': !isVisible,
		'opacity-1 visible': isVisible,
		'px-4 py-6': autocompletePredictions && !apLoading,
		'p-8': !autocompletePredictions && !apLoading
	});

	const onApClick = async (placeId: string, types: string[]) => {
		const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${GOOGLE_MAP_API_KEY}`);
		const data: { results: GooglePlaceResult[] } = await response.json();
		if (data.results[0]) {
			setPlace(
				data.results[0],
				8,
				types.some((item) => item === 'locality')
			);
		}
	};

	const resetState = () => {
		const initialSearchParams = {};
		const initialState = composeInitialStateFromClient({ session: session.session, searchParams: initialSearchParams, ipApi: location });

		resetToInitialState({
			...(initialState.center && { center: initialState.center }),
			zoomLevel: initialState.zoomLevel || 7,
			mapDisplay: initialState.selectedPartner || initialState.selectedTournamentFilter || 'display',
			showAll: false
		});

		resetToursToInitialState(initialState);
	};

	return (
		<div
			className={classes}
			style={{
				boxShadow: '0 3px 12px 0 rgb(0 0 0/0.15)'
			}}
		>
			{apLoading ? (
				<div className="flex items-center justify-center py-6">
					<FontAwesomeIcon icon={faSpinnerThird} spin size="lg" />
				</div>
			) : (
				<>
					{autocompletePredictions && autocompletePredictions.length > 0 ? (
						<div className="flex flex-col">
							{autocompletePredictions.map((item) => {
								return (
									<div
										key={item.place_id}
										className="flex w-full cursor-pointer select-none flex-row items-center rounded-lg px-4 py-2 hover:bg-gray-100"
										onClick={() => {
											onApClick(item.place_id, item.types);
											if (onLocationClick) {
												onLocationClick();
											}
										}}
									>
										<span className="flex size-12 shrink-0 items-center justify-center rounded-xl bg-gray-200">
											<FontAwesomeIcon icon={faLocationDot} size="lg" />
										</span>
										<span className="ml-4 flex-1 text-base font-medium">{item.description}</span>
									</div>
								);
							})}
						</div>
					) : (
						<>
							<div className="flex w-full justify-between">
								<div className="mb-4 ml-2 text-base font-semibold">Search by region</div>
								<div
									className="mb-4 ml-2 flex items-center gap-2 text-sm font-semibold text-blue-600 hover:cursor-pointer hover:underline"
									onClick={resetState}
								>
									Current Location
									<FontAwesomeIcon icon={faMapMarkerAlt} size="lg" />
								</div>
							</div>
							<div
								className="grid"
								style={{
									gridTemplateRows: 'repeat(var(--destination-card-rows, 3), minmax(0, 1fr))',
									gridTemplateColumns: 'repeat(var(--destination-card-columns, 4), minmax(0, 1fr))',
									gridAutoRows: 0,
									rowGap: '6px',
									overflow: 'hidden'
								}}
							>
								{regions.map((item) => {
									return (
										<LocationItem
											key={item.name}
											href="/search"
											name={item.name}
											image={`https://cdn.pickleball.com${item.icon}`}
											onClick={() => {
												setKeyword('');
												if (item.name === 'Anywhere') {
													setPlace(null);
													setPlaceKeyword('Anywhere');
												} else {
													setPlace(item.place as any, 4);
												}
												if (onLocationClick) {
													onLocationClick();
												}
											}}
										/>
									);
								})}
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
};

export const LocationItem = ({ image, name, href, onClick }: { image: string; name: string; href: string; onClick?: () => void }) => {
	const router = useRouter();

	return (
		<div className="flex flex-col gap-2 rounded-2xl p-2 hover:bg-[#EBEBEB]">
			<UnstyledButton
				type="button"
				className="h-auto w-full rounded-xl border border-gray-300 bg-white"
				onClick={() => {
					router.push(href);
					if (onClick) {
						onClick();
					}
				}}
			>
				<img
					src={image}
					alt={name}
					className="inline-block h-auto w-full object-contain align-middle opacity-45"
					style={{ aspectRatio: '1 / 1' }}
				/>
			</UnstyledButton>
			<div className="mx-0.5 overflow-hidden text-sm leading-4 text-[#222222]">{name}</div>
		</div>
	);
};
