import clsx from 'clsx';
import React, { forwardRef } from 'react';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const UnstyledButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
	const { className, ...rest } = props;
	const classes = clsx('', className);

	return <button {...rest} className={classes} ref={ref} />;
});

UnstyledButton.displayName = 'UnstyledButton';
