'use client';

import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons';
import { faArrowLeft, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UnstyledButton } from '@pickleballinc/ui/components/tournaments-app/unstyled-button';
import React, { useState } from 'react';

import { ClearFilterButton } from '@/components/ClearFilterButton';
import { LocationItem } from '@/components/LocationPopover';
import { MobileLocationSearchInput } from '@/components/MobileLocationSearchInput';
import { mobileRegions } from '@/components/regions';
import { useSession } from '@/lib/SessionProvider';
import { useMap } from '@/map/lib/context';
import { useLocation } from '@/providers/location';
import { composeInitialStateFromClient } from '@/tournament/lib/compose-initial-state-from-client';
import { useTournaments } from '@/tournament/lib/context';

export const MobileLocationSearch = () => {
	const [locationSearch, setLocationSearch] = useState<boolean>(false);
	const { place, setPlace, placeKeyword, setPlaceKeyword, resetToInitialState: resetToursToInitialState } = useTournaments();

	const { location } = useLocation();
	const { resetToInitialState } = useMap();
	const session = useSession();

	const resetState = () => {
		const initialSearchParams = {};
		const initialState = composeInitialStateFromClient({ session: session.session, searchParams: initialSearchParams, ipApi: location });

		resetToInitialState({
			...(initialState.center && { center: initialState.center }),
			zoomLevel: initialState.zoomLevel || 7,
			mapDisplay: initialState.selectedPartner || initialState.selectedTournamentFilter || 'display',
			showAll: false
		});

		resetToursToInitialState(initialState);
	};

	return (
		<div className="mt-2 w-full rounded-xl bg-white p-6" style={{ boxShadow: '0 0 0 1px rgb(0 0 0/4%),0 6px 20px rgb(0 0 0/20%)' }}>
			<div className="flex w-full flex-wrap items-center justify-between">
				<h1 className="m-0 mb-4 text-xl font-semibold leading-none">Search location?</h1>
				<div
					className="mb-4 ml-2 flex items-center gap-2 text-base font-semibold text-blue-600 hover:cursor-pointer hover:underline"
					onClick={resetState}
				>
					Current Location
					<FontAwesomeIcon icon={faMapMarkerAlt} size="lg" />
				</div>
			</div>

			<>
				<div
					className="relative flex items-center rounded-xl bg-gray-100 p-4 text-sm font-medium"
					onClick={() => {
						setLocationSearch(true);
					}}
				>
					<FontAwesomeIcon icon={faSearch} width={16} height={16} className="mr-2" />
					{place ? <div>{place.formatted_address}</div> : <>Search location</>}
					{(place || placeKeyword) && (
						<ClearFilterButton
							className="bg-gray-300 hover:bg-gray-400"
							onClick={(e) => {
								e.stopPropagation();
								setPlace(null);
							}}
						/>
					)}
				</div>
				<div className="w-full overflow-scroll py-4">
					<div className="inline-flex flex-nowrap">
						{mobileRegions.map((item) => {
							return (
								<div className="w-32" key={item.name}>
									<LocationItem
										href="/search"
										name={item.name}
										image={`https://cdn.pickleball.com${item.icon}`}
										onClick={() => {
											if (item.name === 'Anywhere') {
												setPlace(null);
												setPlaceKeyword('Anywhere');
											} else {
												setPlace(item.place as any, 4);
											}
										}}
									/>
								</div>
							);
						})}
					</div>
				</div>
				{locationSearch && (
					<div className="fixed inset-0 w-full bg-[#F7F7F7] py-4">
						<div className="relative mx-4 flex h-10 items-center justify-center">
							<UnstyledButton
								type="button"
								className="absolute left-0 top-0 flex size-10 items-center justify-center rounded-full border border-gray-200 bg-white outline-none"
								onClick={() => {
									setLocationSearch(false);
								}}
							>
								<FontAwesomeIcon icon={faArrowLeft} width={16} height={16} className="block size-4" />
							</UnstyledButton>
							<span className="text-lg font-semibold underline underline-offset-8">Locations</span>
						</div>
						<div className="mt-4 size-full bg-white px-4 py-6">
							<MobileLocationSearchInput
								onLocationClick={() => {
									setLocationSearch(false);
								}}
							/>
						</div>
					</div>
				)}
			</>
		</div>
	);
};
