'use client';

import clsx from 'clsx';
import React from 'react';

import { AddDatesContent } from '@/components/AddDatesContent';

interface LocationPopoverProps {
	isVisible: boolean;
}

export const AddDatesPopover = ({ isVisible }: LocationPopoverProps) => {
	const classes = clsx('absolute inset-x-0 top-full mt-4 w-full overflow-y-auto overflow-x-hidden rounded-[32px] bg-white pb-6 pt-8', {
		'opacity-0 invisible pointer-events-none': !isVisible,
		'opacity-1 visible': isVisible
	});

	return (
		<div
			className={classes}
			style={{
				boxShadow: '0 3px 12px 0 rgb(0 0 0/0.15)'
			}}
		>
			<div className="py-2">
				<AddDatesContent />
			</div>
		</div>
	);
};
