'use client';

import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UnstyledButton } from '@pickleballinc/ui/components/tournaments-app/unstyled-button';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useMap } from '@/map/lib/context';
import { useTournaments } from '@/tournament/lib/context';
import usePreloadImages from '@/utils/hooks/usePreloadImages';

export const partners = [
	{
		name: 'featured',
		src: 'featured.svg',
		label: 'Featured'
	},
	// ---------------------------- { This should be shown in the future, currently need to be hidden
	// 	name: 'favorites',
	// 	src: 'favorites.svg',
	// 	label: 'Favorites'
	// },
	{
		name: 'local',
		src: 'local.svg',
		label: 'Local (within a 50 miles radius)'
	},
	{
		name: 'recommended',
		src: 'recommended.svg',
		label: 'Recommended (within a 200 miles radius)'
	},
	// ---------------------------- {  This should be shown in the future, currently need to be hidden
	// 	name: 'special',
	// 	src: 'special.svg',
	// 	label: 'Special'
	// },
	{
		name: 'now-open',
		src: 'now-open.svg',
		label: 'Now Open (within a 200 miles radius, sorted by create date)'
	},
	// TODO: National Tours and Sanctioned will have to be included, we waiting BE and design(dropdowns?)
	{
		name: 'national-tours', // Opens a drop down to select UPA, USAP, Southern Pickleball, USSP, etc.
		src: 'national-tours.svg',
		label: 'National Tours'
	},
	{
		name: 'sanctioned', // Opens a drop down to select PPA, MLP, APP, National Pickleball, etc.
		src: 'sanctioned.svg',
		label: 'Sanctioned'
	},
	{
		name: 'partners', // Opens a drop down to Partners
		src: 'partners.svg',
		label: 'Partners'
	},
	{
		name: 'now-registering',
		src: 'now-registering.svg',
		label: 'Now Registering'
	},
	{
		name: 'now-playing',
		src: 'now-playing.svg',
		label: 'Now Playing'
	}
] as const;

const NATIONAL_TOURS_ALL_LABEL = 'All National Tours' as const;
const SANCTIONED_ALL_LABEL = 'All Sanctioned' as const;

export const nationalTours = [
	{
		name: 'national_all', // hardcoded to have all other objects in this "nationalTours" array fetched combined
		src: 'all_svg_70.svg',
		label: NATIONAL_TOURS_ALL_LABEL
	},
	{
		name: 'sanction_ppa',
		src: 'ppa_svg_70.svg',
		label: 'PPA'
	},
	{
		name: 'sanction_mlp',
		src: 'mlpm_svg_70.svg',
		label: 'MLP'
	},
	{
		name: 'sanction_upa',
		src: 'upa_svg_70.svg',
		label: 'UPA'
	},
	{
		name: 'sanction_np',
		src: 'np_svg_70.svg',
		label: 'NP'
	},
	{
		name: 'sanction_apa',
		src: 'apa.svg',
		label: 'APA'
	},
	{
		name: 'sanction_wpt',
		src: 'wpt.svg',
		label: 'WPT'
	}
] as const;

export const sanctioned = [
	{
		name: 'sanction_all', // hardcoded to have all other objects in this "sanctioned" array fetched combined
		src: 'all_svg_70.svg',
		label: SANCTIONED_ALL_LABEL
	},
	{
		name: 'sanction_upa',
		src: 'upa_svg_70.svg',
		label: 'UPA'
	},
	{
		name: 'sanction_pco',
		src: 'pc_svg_70.svg',
		label: 'Pickleball Canada'
	},
	{
		name: 'sanction_paa',
		src: 'paa_svg_70.svg',
		label: 'Pickleball Australia'
	},
	{
		name: 'sanction_gpa',
		src: 'sp_svg_70.svg',
		label: 'Southern Pickleball'
	},

	{
		name: 'sanction_ssipa',
		src: 'ussp_svg_70.svg',
		label: 'USSP'
	},
	{
		name: 'sanction_usapa',
		src: 'usap_svg_70.svg',
		label: 'USAP'
	}
	// {
	// 	name: 'sanction_usapa',
	// 	src: '',
	// 	label: 'Sanctioned Events'
	// },

	// {
	// 	name: 'sanction_pig',
	// 	//Shouldn't be shown, so image is empty
	// 	src: '',
	// 	label: 'Pickleball Is Great'
	// }
] as const;

export const menuPartners = [
	{
		name: 'picklr',
		src: 'picklr.webp',
		label: 'Picklr'
	},
	{
		name: 'sanction_pig',
		src: 'pig.webp',
		label: 'Pickleball is Great'
	}
] as const;

interface PartnersWidget extends React.CSSProperties {
	'--contentscroller_auto-columns'?: string;
	'--contentscroller_scrollsnaptype'?: string;
}

interface PartnerItemProps extends React.HTMLProps<HTMLDivElement> {
	name: string;
	src: string;
}

const PartnerItem = ({ ...props }: PartnerItemProps) => {
	const { className, name, ...rest } = props;

	const { setShowAll } = useMap();
	const { selectedTournamentFilter, setSelectedTournamentFilter, setSelectedPartner, setNowRegistering, setNowPlaying, setMyTournaments } =
		useTournaments();
	const [showModal, setShowModal] = useState(false);
	const [openModalName, setOpenModalName] = useState<string | undefined>(undefined);

	const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
	const itemRef = useRef<HTMLDivElement>(null);
	const modalRef = useRef<HTMLDivElement>(null);

	const classes = clsx('hover:before-[""] relative flex flex-col items-center justify-center bg-white px-5 py-1', className);
	const hoverClasses = clsx(
		'relative flex h-[70px] cursor-pointer items-center hover:before:absolute hover:before:inset-x-0 hover:before:bottom-[-4px] hover:before:h-[3px]',
		{
			'before:absolute before:inset-x-0 before:bottom-[-4px] before:h-[3px] before:bg-blue-500':
				selectedTournamentFilter === name || openModalName === name
		},
		{
			'grayscale brightness-50 contrast-150 hover:brightness-50 hover:before:bg-black':
				selectedTournamentFilter !== name && openModalName !== name
		}
	);

	const handlePartnerClick = () => {
		// Only open the modal for 'national-tours' or 'sanctioned'
		if (name === 'national-tours' || name === 'sanctioned' || name === 'partners') {
			setSelectedTournamentFilter(null);

			// Calculate and set the modal position
			if (itemRef.current) {
				const rect = itemRef.current.getBoundingClientRect();
				const modalWidth = 360;
				const screenWidth = window.innerWidth;

				let left = rect.left + rect.width / 2 - modalWidth / 2;

				if (left + modalWidth > screenWidth) {
					left = screenWidth - modalWidth - 10;
				}

				setModalPosition({
					top: rect.bottom + 5,
					left: left + window.scrollX
				});
			}

			setShowModal(!showModal);

			if (!showModal) {
				setOpenModalName(name);
			} else {
				setOpenModalName(undefined);
			}
		} else if (selectedTournamentFilter === name) {
			setSelectedTournamentFilter(null);
			setShowAll(false);
			setOpenModalName(undefined);
			setSelectedPartner(null);
		} else {
			if (name === 'featured' || name === 'local' || name === 'recommended' || name === 'now-open') {
				setNowRegistering(false);
				setNowPlaying(false);
				setMyTournaments(false);
			}
			setSelectedTournamentFilter(name);
			setShowAll(true);
			setOpenModalName(undefined);
			setSelectedPartner(null);
		}
	};

	const onSelectTour = (itemName: string) => {
		setSelectedPartner(itemName);
		setMyTournaments(false);
		setSelectedTournamentFilter(null);
		setShowModal(false);
		setOpenModalName(undefined);
	};

	const handleClickOutside = useCallback((event: MouseEvent) => {
		if (
			modalRef.current &&
			!modalRef.current.contains(event.target as Node) &&
			itemRef.current &&
			!itemRef.current.contains(event.target as Node)
		) {
			setOpenModalName(undefined);
			setShowModal(false);
		}
	}, []);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, [handleClickOutside]);

	const imageSrcArray = useMemo(() => {
		const items = openModalName === 'national-tours' ? nationalTours : openModalName === 'partners' ? menuPartners : sanctioned;
		return items.map((el) => `/svg/${el.src}`);
	}, [openModalName]);

	// Use the custom hook to preload images (because icons form CDN are not cached)
	const imagesLoaded = usePreloadImages(imageSrcArray);

	const checkIfAllLabel = (label: any) => [NATIONAL_TOURS_ALL_LABEL, SANCTIONED_ALL_LABEL].includes(label);

	const modalContent = showModal && openModalName && imagesLoaded && (
		<div
			ref={modalRef}
			className={`absolute z-[999] w-[346px] rounded-xl border border-gray-300 bg-white p-2 shadow-lg`}
			style={{
				top: 80,
				left: `${modalPosition.left}px`
			}}
		>
			<div className="grid grid-cols-3 gap-4 p-4">
				{(openModalName === 'national-tours' ? nationalTours : openModalName === 'partners' ? menuPartners : sanctioned).map((el, index) => (
					<div
						className="contrast-110 flex cursor-pointer flex-col items-center grayscale hover:brightness-100 hover:grayscale-0"
						key={index}
					>
						<div className="rounded-md border p-2">
							<div className="flex size-16 cursor-pointer items-center justify-center" onClick={() => onSelectTour(el.name)}>
								<img src={`/svg/${el.src}`} alt={el.label} className="object-contain" />
							</div>
						</div>
						<span className="mt-2 text-center text-sm font-medium text-gray-700">{checkIfAllLabel(el.label) ? 'All' : el.label}</span>
					</div>
				))}
			</div>
		</div>
	);

	return (
		<>
			<div ref={itemRef} {...rest} className={classes} style={{ width: 'max-content', minWidth: 56 }}>
				<div className={hoverClasses} onClick={handlePartnerClick}>
					<img className="h-14" src={`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleballtournaments-app/${name}-selected.svg`} alt={name} />
				</div>
			</div>
			{modalContent}
		</>
	);
};

export const PartnersList = () => {
	const [showLeftArrow, setShowLeftArrow] = useState(false);
	const [showRightArrow, setShowRightArrow] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const div = containerRef.current;

		// Define the event handler
		const handleScroll = () => {
			if (div) {
				div.scrollLeft > 10 ? setShowLeftArrow(true) : setShowLeftArrow(false);
				Math.ceil(div.scrollLeft) < Math.ceil(div.scrollWidth - div.clientWidth) ? setShowRightArrow(true) : setShowRightArrow(false);
			}
		};

		handleScroll();

		window.addEventListener('resize', handleScroll);

		// Attach the scroll event listener
		div?.addEventListener('scroll', handleScroll);

		// Cleanup function to remove the event listener
		return () => {
			window.removeEventListener('resize', handleScroll);
			div?.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const scroll = useCallback((direction: 'left' | 'right') => {
		const div = containerRef.current;
		if (div) {
			const position = direction === 'right' ? div.scrollLeft + 250 : div.scrollLeft - 250;
			const maxScrollLeft = div.scrollWidth - div.clientWidth;
			const left = direction === 'right' ? (position < maxScrollLeft ? position : maxScrollLeft) : position < 0 ? 0 : position;
			div.scrollTo({ left, behavior: 'smooth' });
		}
	}, []);

	const styles: PartnersWidget = {
		'--contentscroller_auto-columns': 'max-content',
		'--contentscroller_scrollsnaptype': 'none'
	};

	return (
		<div style={styles}>
			<div>
				<div
					className="grid justify-center"
					style={{
						gridAutoFlow: 'column',
						overflow: 'clip'
					}}
				>
					<div>
						<div
							className="grid"
							style={{
								gridTemplate: "var( --contentscroller_layout_grid-template, 'header' 'scroller' 'footer'/minmax(0,1fr) )"
							}}
						>
							{showLeftArrow && (
								<div
									className="left-arrow after:content[''] absolute z-10 flex h-full items-center px-2 after:h-full after:w-10"
									style={{
										background: 'linear-gradient(to left,rgb(255 255 255/0),white 40px)'
									}}
								>
									<UnstyledButton
										className="group inline-flex size-7 items-center justify-center rounded-full bg-white hover:scale-[1.04] hover:shadow-[0_6px_16px_rgba(0,0,0,0.12)]"
										style={{
											border: '0.5px solid rgb(0 0 0/0.3)',
											transition: 'box-shadow 0.2s cubic-bezier(0.2,0,0,1)'
										}}
										onClick={() => scroll('left')}
									>
										<FontAwesomeIcon icon={faAngleLeft} size="sm" className="group-hover:scale-[0.96]" />
									</UnstyledButton>
								</div>
							)}
							<div
								className=""
								style={{
									gridArea: 'var(--contentscroller_scroller_grid-area,scroller)'
								}}
							>
								<div>
									<div style={{ aspectRatio: 'unset' }}>
										<div style={{ height: '100%', minWidth: 0, position: 'unset' }}>
											<div
												className="no-scrollbar"
												ref={containerRef}
												style={{
													margin: 0,
													gridAutoFlow: 'column',
													justifyContent: 'flex-start',
													scrollSnapType: 'none',
													gridTemplateAreas: 'var(--contentscroller_areas,none)',
													gridTemplateRows: 'var(--contentscroller_rows,none)',
													gridAutoColumns:
														'var( --contentscroller_auto-columns,calc((100% - var(--contentscroller_gap,16px) * (var(--contentscroller_visible-items,unset) - 1)) / var(--contentscroller_visible-items,unset)) )',
													display: 'grid',
													overflow: 'var(--contentscroller_overflow,auto hidden)',
													height: 'var(--contentscroller_height)',
													minHeight: 'var(--contentscroller_min-height)',
													gap: 0
												}}
											>
												{partners.map((item, i) => {
													const classes = clsx({});
													return item.src && <PartnerItem key={i} name={item.name} src={item.src} className={classes} />;
												})}
											</div>
										</div>
									</div>
								</div>
							</div>
							{showRightArrow && (
								<div
									className="right-arrow before:content[''] absolute right-0 z-10 flex h-full items-center pr-2 before:h-full before:w-10 md:right-[-8px]"
									style={{
										background: 'linear-gradient(to right,rgb(255 255 255/0), white 40px)'
									}}
								>
									<UnstyledButton
										className="group inline-flex size-7 items-center justify-center rounded-full bg-white hover:scale-[1.04] hover:shadow-[0_6px_16px_rgba(0,0,0,0.12)]"
										style={{
											border: '0.5px solid rgb(0 0 0/0.3)',
											transition: 'box-shadow 0.2s cubic-bezier(0.2,0,0,1)'
										}}
										onClick={() => scroll('right')}
									>
										<FontAwesomeIcon icon={faAngleRight} size="sm" className="group-hover:scale-[0.96]" />
									</UnstyledButton>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
