'use client';

import * as React from 'react';

import { GooglePlaceResult } from '@/tournament/lib/google.type';

export type SearchType = 'map-area' | 'manual';

export interface LatLng {
	lat: number;
	lng: number;
}

export interface MapBounds {
	ne_lat: number;
	ne_lng: number;
	sw_lat: number;
	sw_lng: number;
}

export interface Dates {
	from?: string;
	to?: string;
	months?: number;
}

export interface Place {
	formatted_address?: string;
	place_id?: string;
}

export interface TournamentsState {
	bounds: MapBounds | null;
	center: LatLng | null;
	data: any;
	dates: Dates | null;
	clubId: string | null;
	keyword: string | null;
	limit: number;
	featured: boolean;
	loading: boolean;
	managing: boolean;
	mapArea: boolean;
	userLocationFetch: boolean;
	selectedTournamentFilter: string | null;
	myTournaments: boolean;
	nowPlaying: boolean;
	nowRegistering: boolean;
	pastEvents: boolean;
	mtPast: boolean;
	place: GooglePlaceResult | null;
	placeKeyword: string | null;
	page: number;
	registeredFor: boolean;
	searchType: SearchType | null;
	zoomLevel: number | null;
	selectedPartner: string | null;
}

export const initialState: TournamentsState = {
	bounds: {
		ne_lat: 62.58868842395031,
		ne_lng: 60.99620754284127,
		sw_lat: 5.728525222579206,
		sw_lng: -130.61679263144754
	},
	clubId: null,
	center: {
		lat: 39.67917984404462,
		lng: -95.8065000871444
	},
	data: null,
	dates: null,
	keyword: null,
	limit: 25,
	loading: true,
	mapArea: false,
	featured: false,
	managing: false,
	myTournaments: true,
	nowPlaying: false,
	nowRegistering: false,
	pastEvents: false,
	userLocationFetch: false,
	selectedTournamentFilter: null,
	mtPast: false,
	place: null,
	placeKeyword: null,
	page: 1,
	registeredFor: false,
	searchType: null,
	zoomLevel: 3.6,
	selectedPartner: null
};

interface TournamentsContextInterface extends TournamentsState {
	clearDates: () => void;
	getTournaments: () => void;
	setBounds: (bounds: MapBounds | null, zoomLevel?: number, center?: { lat: number; lng: number }, mapArea?: boolean) => void;
	setDates: (dates: { from?: string; to?: string; months?: number }) => void;
	setKeyword: (keyword: string | null) => void;
	setLimit: (limit: number) => void;
	setManaging: (setManaging: boolean) => void;
	setMyTournaments: (myTournaments: boolean) => void;
	setNowPlaying: (nowPlaying: boolean) => void;
	setNowRegistering: (nowRegistering: boolean) => void;
	setMtPast: (mtPast: boolean) => void;
	setPage: (page: number) => void;
	setPastEvents: (past: boolean) => void;
	setPlace: (place: GooglePlaceResult | null, zoom?: number, isAutocomplete?: boolean) => void;
	setSelectedTournamentFilter: (tournament_filter: string | null) => void;
	setFeatured: (featured: boolean) => void;
	setPlaceKeyword: (keyword: string) => void;
	setRegisteredFor: (featured: boolean) => void;
	setSelectedPartner: (partner: string | null) => void;
	resetToInitialState: (state: TournamentsState) => void;
}

const stub = (): never => {
	throw new Error('You forgot to wrap your component in <Auth0Provider>.');
};

const initialContext: TournamentsContextInterface = {
	...initialState,
	clearDates: stub,
	getTournaments: stub,
	setBounds: stub,
	setDates: stub,
	setKeyword: stub,
	setLimit: stub,
	setManaging: stub,
	setMyTournaments: stub,
	setNowPlaying: stub,
	setNowRegistering: stub,
	setFeatured: stub,
	setMtPast: stub,
	setPage: stub,
	setPastEvents: stub,
	setPlace: stub,
	setSelectedTournamentFilter: stub,
	setPlaceKeyword: stub,
	setRegisteredFor: stub,
	setSelectedPartner: stub,
	resetToInitialState: stub
};

export const TournamentsContext = React.createContext<TournamentsContextInterface>(initialContext);

export const useTournaments = (context = TournamentsContext): TournamentsContextInterface => React.useContext(context) as TournamentsContextInterface;
