'use client';

import React from 'react';

import { ClearFilterButton } from '@/components/ClearFilterButton';
import { useTournaments } from '@/tournament/lib/context';

import { FormLabel } from '../components/FormLabel';

interface KeywordProps {
	onSearchActive: (active: boolean) => void;
	onChange: (keyword: string) => void;
}

export const Keyword = ({ onSearchActive, onChange }: KeywordProps) => {
	const { keyword, setKeyword, getTournaments } = useTournaments();

	return (
		<div className="min-w-0 max-w-64" style={{ flex: '1 0 0%' }}>
			<div className="relative -m-px flex min-w-0 items-center" style={{ flex: '1 0 0%' }}>
				<FormLabel as="label" isFirst>
					<div className="relative z-[1] px-8 py-3.5">
						<div className="pb-0.5 text-xs font-medium leading-[16px]">Search by</div>
						<input
							type="text"
							className="m-0 block w-full cursor-pointer bg-transparent bg-none text-sm font-medium leading-[1.125rem] outline-none placeholder:font-normal placeholder:text-[#717171] visited:outline-none focus:border-0 focus:outline-0"
							placeholder="Keyword..."
							value={keyword || ''}
							onKeyDown={(e) => {
								if (e.key === 'Enter' && e.currentTarget.value.length > 0) {
									getTournaments();
								}
							}}
							onChange={(e) => {
								if (onChange) {
									onChange(e.target.value);
								}
							}}
							onFocus={() => {
								onSearchActive(true);
							}}
							onBlur={() => {
								onSearchActive(false);
							}}
						/>
						{keyword && keyword.length > 0 && (
							<ClearFilterButton
								onClick={() => {
									setKeyword(null);
								}}
							/>
						)}
					</div>
				</FormLabel>
			</div>
		</div>
	);
};
