'use client';

import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOutsideClick } from '@pickleballinc/features/src/tournaments-app/lib/outside-click';
import { UnstyledButton } from '@pickleballinc/ui/components/tournaments-app/unstyled-button';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';

import { ClearFilterButton } from '@/components/ClearFilterButton';

import { AddDatesPopover } from '../components/AddDatesPopover';
import { FormLabel } from '../components/FormLabel';
import { useTournaments } from '../tournament/lib/context';

interface AddDatesProps {
	searchActive: boolean;
	onSearchActive: (active: boolean) => void;
	onSubmit: () => void;
}

export const AddDates = ({ searchActive, onSearchActive, onSubmit }: AddDatesProps) => {
	const { dates, clearDates, pastEvents } = useTournaments();
	const [formOpen, setFormOpen] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, setToOpen] = useState(false);

	const formButtonRef = useRef<HTMLButtonElement>(null);
	const ref = useOutsideClick(() => {
		setFormOpen(false);
		setToOpen(false);
		onSearchActive(false);
	});

	const buttonClasses = clsx(
		'm-0 inline-block h-12 max-h-12 min-h-12 w-full items-center justify-center overflow-hidden rounded-md bg-blue-500 align-middle transition hover:bg-blue-700',
		{
			'max-w-12': !searchActive || !formOpen
			/* 'max-w-12': searchActive || formOpen */
		}
	);

	const buttonLabelClasses = clsx('block pl-2 pr-1 font-medium leading-none text-white', {
		'opacity-0': !searchActive || !formOpen
		/* 'opacity-0': searchActive || formOpen */
	});

	return (
		<div className="flex" style={{ flex: '1 0 0%' }} ref={ref}>
			<div className="relative -m-px flex min-w-0 items-center" style={{ flex: '1 0 0%' }}>
				<FormLabel
					onClick={() => {
						setToOpen(false);
						setFormOpen(true);
					}}
					isLast
				>
					<div className="relative z-[1] px-6 py-3.5">
						<div className="whitespace-nowrap pb-0.5 text-xs font-medium leading-[16px]">Dates</div>
						{pastEvents ? (
							<div className="truncate pb-0.5 text-sm font-medium leading-[16px]">Past events</div>
						) : (
							<>
								{dates?.from || dates?.to ? (
									<div className="truncate pb-0.5 text-sm font-normal leading-[16px]">
										<span className="font-medium">
											{dates?.from && (
												<>
													{new Date(dates.from).toLocaleDateString('en-US', {
														day: '2-digit', // Numeric day
														month: 'short', // Abbreviated month
														year: '2-digit'
													})}
												</>
											)}
										</span>
										<span className="font-medium">
											{dates?.to && (
												<>
													<span className="mx-2">–</span>
													{new Date(dates.to).toLocaleDateString('en-US', {
														day: '2-digit', // Numeric day
														month: 'short', // Abbreviated month,
														year: '2-digit'
													})}
												</>
											)}
										</span>
									</div>
								) : (
									<div className="truncate pb-0.5 text-sm font-normal leading-[16px] text-[#717171]">Select</div>
								)}
							</>
						)}
						{(dates?.from || dates?.to || pastEvents) && (
							<ClearFilterButton
								onClick={() => {
									clearDates();
								}}
							/>
						)}
					</div>
				</FormLabel>
				<div className="relative ml-[-6px] pr-[9px]" style={{ flex: '0 0 auto' }}>
					<UnstyledButton
						type="button"
						className={buttonClasses}
						ref={formButtonRef}
						style={{
							transition: '0.2s max-width cubic-bezier(0.2,0,0,1)'
						}}
						onClick={() => {
							onSubmit();
						}}
					>
						<div className="relative z-10 inline-flex p-4">
							<FontAwesomeIcon icon={faSearch} className="block size-4 !align-baseline text-base leading-none text-white" />
							<span className={buttonLabelClasses}>Search</span>
						</div>
					</UnstyledButton>
				</div>
			</div>
			{formOpen && (
				<div>
					<AddDatesPopover isVisible={formOpen} />
				</div>
			)}
		</div>
	);
};
