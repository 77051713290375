'use client';

import * as React from 'react';

import { IpApi } from '@/types/ip-api';

interface LocationContextState {
	location: IpApi | null;
}

interface LocationContextInterface extends LocationContextState {
	setLocation: (location: IpApi) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const stub = (): never => {
	throw new Error('You likely forgot to wrap your component in a <AppProvider>');
};

const initialContext: LocationContextInterface = {
	location: null,
	setLocation: stub
};

const LocationContext = React.createContext<LocationContextInterface>(initialContext);

export const useLocation = (context = LocationContext) => {
	return React.useContext(context) as LocationContextInterface;
};

interface SearchProviderProps {
	children: React.ReactNode;
	initialLocation: IpApi | null;
}

export const LocationProvider = ({ children, initialLocation }: SearchProviderProps) => {
	const [location, setLocationState] = React.useState<IpApi | null>(initialLocation);

	const setLocation = React.useCallback((location: IpApi) => {
		setLocationState(location);
	}, []);

	const contextValue = React.useMemo(() => {
		return {
			location,
			setLocation
		};
	}, [location, setLocation]);

	return <LocationContext.Provider value={contextValue}>{children}</LocationContext.Provider>;
};
