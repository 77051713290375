import React from 'react';

import { AddDatesContent } from '@/components/AddDatesContent';

export const MobileDatesPicker = () => {
	return (
		<div className="mt-2 w-full rounded-xl bg-white" style={{ boxShadow: '0 0 0 1px rgb(0 0 0/4%),0 6px 20px rgb(0 0 0/20%)' }}>
			<div className="p-6">
				<h1 className="m-0 mb-4 text-xl font-semibold leading-none">When?</h1>
			</div>
			<AddDatesContent dateNoMonths={1} />
		</div>
	);
};
