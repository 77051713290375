import React, { useMemo, useState } from 'react';

import { useTournaments } from '../tournament/lib/context';

function addMonthsToDate(dateString: string, monthsToAdd: number) {
	// Parse the dateString into a Date object
	const date = new Date(dateString);

	// Add the specified number of months
	date.setMonth(date.getMonth() + monthsToAdd);

	// Return the new date
	return date;
}

interface CircleWidgetStyles extends React.CSSProperties {
	'--angle'?: string;
}

function computePosition({ angleInDegrees, centerX, centerY, radius }: { angleInDegrees: number; centerX: number; centerY: number; radius: number }) {
	const _ = ((angleInDegrees - 90) * Math.PI) / 180;
	return {
		x: centerX + radius * Math.cos(_),
		y: centerY + radius * Math.sin(_)
	};
}

export const CircleWidget = ({
	value,
	radius,
	steps,
	onChange
}: {
	value?: number;
	radius: number;
	steps: number;
	onChange?: (value: number) => void;
}) => {
	const { setDates } = useTournaments();
	const [uncontrolledState, setUncontrolledState] = useState(1);

	const derivedValue = value || uncontrolledState;

	const styles: CircleWidgetStyles = {
		'--angle': `${(360 / steps) * derivedValue}deg`
	};

	const renderDivs = useMemo(() => {
		const divs: React.ReactNode[] = [];
		for (let i = 1; i <= steps; i++) {
			const radius = Math.round(Math.max(0, 290 / 2 - 30));
			const angleInDegrees = i * 30;
			const { x, y } = computePosition({ angleInDegrees, centerX: 145, centerY: 145, radius });
			divs.push(
				<span
					key={i}
					className="absolute flex size-12 cursor-pointer items-center justify-center rounded-full"
					style={{
						left: `${x}px`,
						top: `${y}px`,
						transform: 'translate(-50%,-50%)'
					}}
					onClick={() => {
						setUncontrolledState(i);
						if (onChange) {
							onChange(i);
						}
						const endDate = addMonthsToDate(new Date().toString(), i);
						setDates({ from: new Date().toString(), to: endDate.toString(), months: i });
					}}
				>
					<div
						className={`h-3 w-px rounded-full bg-gray-200 ${i === steps ? 'bg-transparent' : 'bg-gray-600'}`}
						style={{
							rotate: `${angleInDegrees}deg`
						}}
					/>
				</span>
			);
		}
		return divs;
	}, [steps, onChange, setDates]);

	// const y = 60;
	const b = 52;
	const C = 4;
	// const q = 6;
	// const S = 12;

	const path = useMemo(() => {
		const t = derivedValue * 30;
		const s = 290 / 2 - 4;
		const o = C;
		const _ = s - b;
		const l = _ + o;
		const Mt = 145 + 12;
		const zt = 145 + 12;

		const u = computePosition({
			angleInDegrees: 0,
			centerX: Mt,
			centerY: zt,
			radius: s - o
		});
		const c = computePosition({
			angleInDegrees: t,
			centerX: Mt,
			centerY: zt,
			radius: s
		});
		const f = computePosition({
			angleInDegrees: 0,
			centerX: Mt,
			centerY: zt,
			radius: l
		});
		const h = (o / (2 * Math.PI * s)) * 360;
		const p = (o / (2 * Math.PI * _)) * 360;
		const w = computePosition({
			angleInDegrees: 0 + h,
			centerX: Mt,
			centerY: zt,
			radius: s
		});
		const v = computePosition({
			angleInDegrees: t - 360,
			centerX: Mt,
			centerY: zt,
			radius: s
		});
		const x = computePosition({
			angleInDegrees: 0 + p,
			centerX: Mt,
			centerY: zt,
			radius: _
		});
		const k = computePosition({
			angleInDegrees: t - 360,
			centerX: Mt,
			centerY: zt,
			radius: _
		});

		const j = Math.abs(t - 0) > 180 + h ? 1 : 0;
		const y = Math.abs(t - 0) > 180 + p ? 1 : 0;

		return [
			'M',
			u.x,
			u.y,
			'A',
			o,
			o,
			0,
			0,
			1,
			w.x,
			w.y,
			'A',
			s,
			s,
			0,
			j,
			1,
			v.x,
			v.y,
			'A',
			26,
			26,
			0,
			0,
			1,
			c.x,
			c.y,
			'A',
			26,
			26,
			0,
			0,
			1,
			k.x,
			k.y,
			'A',
			_,
			_,
			0,
			y,
			0,
			x.x,
			x.y,
			'A',
			o,
			o,
			0,
			0,
			1,
			f.x,
			f.y,
			'Z'
		].join(' ');
	}, [derivedValue]);

	/* const { d } = useSpring({
		d: toggle
			? 'M10,80 C40,20 65,20 95,80 S150,140 180,80' // Path 2
			: 'M10,20 C40,80 65,80 95,20 S150,20 180,80', // Path 1
		config: {
			...config.stiff,
			...config.wobbly
		}
	}); */

	// const P = 6;
	// const O = 1;
	// const n = P / O;
	// const nt =
	// 	P ??
	// 	P ??
	// 	F(1, {
	// 		maxValue: 12,
	// 		step: 1
	// 	});

	// const Ct = (step: number) => {
	// 	const n = nt / O;
	// };

	return (
		<div
			className="relative flex aspect-square max-h-[290px] min-h-[290px] min-w-[290px] max-w-[290px] flex-1 shrink grow-0 items-center justify-center"
			style={styles}
		>
			<div
				className="absolute inset-0 size-full rounded-full bg-[#e6e6e6]"
				style={{
					boxShadow: '0 -5px 5px rgba(0,0,0,0.2),0 5px 5px #ffffff,inset 0 -20px 30px #ffffffe5,inset 0 10px 20px rgba(0,0,0,0.2)'
				}}
			/>
			<div className="circle-widget absolute inset-0 blur-[5px]" />
			<div
				style={{
					boxShadow:
						'0 -5px 5px rgba(255,255,255,0.4),inset 0 5px 3px rgba(255,255,255,0.8),inset 0 -5px 5px rgba(0,0,0,0.2),0 11px 18px rgba(0,0,0,0.18),0 -20px 30px rgba(255,255,255,0.8)'
				}}
				className="absolute inset-[60px] flex items-center justify-center rounded-full bg-[#F7F7F7]"
			>
				<div className="flex flex-col items-center justify-center">
					<div className="text-[96px] font-extrabold leading-[89px]">{derivedValue}</div>
					<div className="text-lg font-extrabold leading-6">months</div>
				</div>
			</div>
			<div
				className="handler"
				style={{
					transform: 'translate(-50%,8px) rotate(var(--angle))'
				}}
			/>
			{renderDivs}
			{/* <div className="circle-widget pointer-events-none absolute size-full blur-[5px]" style={styles} /> */}
			<svg
				aria-hidden="true"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				className="pointer-events-none relative"
				style={{ position: 'absolute', width: radius * 2 + 24 + 'px', height: radius * 2 + 24 + 'px', top: -12, left: -12 }}
			>
				<defs>
					<mask id="trackBackground">
						<rect x="0" y="0" width="100%" height="100%" fill="black"></rect>
						<circle cx="50%" cy="50%" fill="none" r="115" stroke="white" strokeWidth="60"></circle>
						<path
							fill="black"
							filter="blur(10px)"
							d="M 157 157 L -20 157 L -20 -7.372125185138003 L 142.6193024466364 -7.372125185138003 L 157 157 L 157 334 L -20 334 L -20 157 L 157 157 L 334 157 L 334 334 L 157 334 L 157 157 L 273.6726188957804 40.32738110421967 L 334 40.32738110421967 L 334 157 L 157 157"
						></path>
					</mask>
					<radialGradient id="trackBackgroundInnerShadow">
						<stop offset="56%" stopColor="rgba(28, 52, 162, 0.6)"></stop>
						<stop offset="62%" stopColor="transparent"></stop>
						<stop offset="66%" stopColor="transparent"></stop>
						<stop offset="89%" stopColor="rgba(28, 52, 162, 0.4)"></stop>
					</radialGradient>
					<mask id="trackForeground">
						<rect x="0" y="0" width="100%" height="100%" fill="black"></rect>
						<path fill="white" stroke="white" d={path}></path>
					</mask>
					<radialGradient id="trackForegroundFill">
						<stop offset="69%" stopColor="#3065BE"></stop>
						<stop offset="100%" stopColor="#254EDB"></stop>
					</radialGradient>
					<filter id="trackForegroundInsetShadow1">
						<feOffset dx="0" dy="-10"></feOffset>
						<feGaussianBlur stdDeviation="10" result="offset-blur"></feGaussianBlur>
						<feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"></feComposite>
						<feFlood floodColor="#7095e9" floodOpacity="0.7" result="color"></feFlood>
						<feComposite operator="in" in="color" in2="inverse" result="shadow"></feComposite>
						<feComponentTransfer in="shadow" result="shadow">
							<feFuncA type="linear" slope="1"></feFuncA>
						</feComponentTransfer>
					</filter>
					<filter id="trackForegroundInsetShadow2">
						<feOffset dx="0" dy="-2"></feOffset>
						<feGaussianBlur stdDeviation="4" result="offset-blur"></feGaussianBlur>
						<feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"></feComposite>
						<feFlood floodColor="#114EDB" floodOpacity="1" result="color"></feFlood>
						<feComposite operator="in" in="color" in2="inverse" result="shadow"></feComposite>
						<feComponentTransfer in="shadow" result="shadow">
							<feFuncA type="linear" slope="1"></feFuncA>
						</feComponentTransfer>
					</filter>
					<filter id="trackForegroundInsetShadow3">
						<feOffset dx="0" dy="-10"></feOffset>
						<feGaussianBlur stdDeviation="5" result="offset-blur"></feGaussianBlur>
						<feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"></feComposite>
						<feFlood floodColor="white" floodOpacity="0.1" result="color"></feFlood>
						<feComposite operator="in" in="color" in2="inverse" result="shadow"></feComposite>
						<feComponentTransfer in="shadow" result="shadow">
							<feFuncA type="linear" slope="1"></feFuncA>
						</feComponentTransfer>
					</filter>
				</defs>
				<path fill="#254EDB" filter="url(#trackForegroundDropShadow1)" mask="url(#trackBackground)" d={path}></path>
				<path fill="#254EDB" filter="url(#trackForegroundDropShadow2)" mask="url(#trackBackground)" d={path}></path>
				<circle cx="50%" cy="50%" fill="url(#trackForegroundFill)" mask="url(#trackForeground)" r="145"></circle>
				<path fill="#254EDB" filter="url(#trackForegroundInsetShadow1)" stroke="#254EDB" d={path}></path>
				<path fill="#254EDB" filter="url(#trackForegroundInsetShadow2)" stroke="#254EDB" d={path}></path>
				<path fill="#254EDB" filter="url(#trackForegroundInsetShadow3)" stroke="#254EDB" d={path}></path>
			</svg>
		</div>
	);
};
