import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React from 'react';

interface ClearFilterButtonProps extends React.HTMLProps<HTMLDivElement> {}

export const ClearFilterButton = ({ ...props }: ClearFilterButtonProps) => {
	const { className, ...rest } = props;

	const classes = clsx(
		'absolute right-4 top-1/2 -mt-3 inline-flex size-6 cursor-pointer items-center justify-center rounded-full bg-gray-100 hover:bg-gray-300',
		className
	);

	return (
		<span {...rest} className={classes}>
			<FontAwesomeIcon icon={faTimes} size="sm" />
		</span>
	);
};
