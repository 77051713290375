import { Url } from 'next/dist/shared/lib/router/router';

import { SessionData } from '@/lib/sessionsv2/types';

const SSO_BASE = process.env.NEXT_PUBLIC_SSO_ENDPOINT;

export const isCurrentUser = (session: SessionData | undefined, userId: string | undefined) => {
	return session?.user?.token && userId?.toLowerCase() === session.user.pbUuid.toLowerCase();
};

const isSsoURL = (url: string | URL | Url) => {
	if (!SSO_BASE) return false;

	if (typeof url === 'string') {
		return url.includes(SSO_BASE);
	}

	return url ? (url.href ? url.href.includes(SSO_BASE) : false) : false;
};

export default isSsoURL;
