'use client';

import React from 'react';

import { PartnersList } from './PartnersList';

export const Partners = () => {
	return (
		<>
			<div className="z-50 hidden items-center md:top-[196px] md:block" style={{ boxShadow: '0 0 2px rgb(0 0 0/20%)' }}>
				<div className="grow">
					<div>
						<div style={{ display: 'contents' }}>
							<div className="bg-white md:pr-6">
								<div className="grid" style={{ gridTemplateColumns: '100%', maxHeight: 78 }}>
									<div className="gg-grid flex w-full items-center gap-0 md:gap-4" style={{ overflowClipMargin: 4, height: 78 }}>
										<div className="grow-1 relative flex-1 shrink-0">
											<PartnersList />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
