'use client';

import * as React from 'react';

interface SearchHeaderState {
	scrollTop: number;
	expanded: boolean;
	initialExpanded: boolean;
}

interface SearchHeaderContextInterface extends SearchHeaderState {
	toggleExpand: (expanded: boolean) => void;
}

const stub = (): never => {
	throw new Error('You forgot to wrap your component in <Auth0Provider>.');
};

const initialContext: SearchHeaderContextInterface = {
	scrollTop: 0,
	expanded: true,
	toggleExpand: stub,
	initialExpanded: true
};

export const SearchHeaderContext = React.createContext<SearchHeaderContextInterface>(initialContext);

export const useSearchHeader = (context = SearchHeaderContext): SearchHeaderContextInterface =>
	React.useContext(context) as SearchHeaderContextInterface;
