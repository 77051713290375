import { TournamentsState } from '@/tournament/lib/context';
import { GooglePlaceResult } from '@/tournament/lib/google.type';
import {
	clubIdsMapping,
	composeBounds,
	composeCenter,
	ComposeStateClientProps,
	getValueByKey,
	initialState
} from '@/tournament/lib/initial-state-sharing';

export const composeInitialStateFromClient = ({ session, searchParams, ipApi }: ComposeStateClientProps): TournamentsState => {
	const bounds = composeBounds(
		getValueByKey(searchParams, 'ne_lat'),
		getValueByKey(searchParams, 'ne_lng'),
		getValueByKey(searchParams, 'sw_lat'),
		getValueByKey(searchParams, 'sw_lng')
	);
	const zoomLevel = getValueByKey(searchParams, 'zoom_level');
	let center = composeCenter(getValueByKey(searchParams, 'center_lat'), getValueByKey(searchParams, 'center_lng'));

	const keyword = getValueByKey(searchParams, 'keyword');
	const limit = getValueByKey(searchParams, 'limit');
	const page = getValueByKey(searchParams, 'page');
	let placeKeyword = getValueByKey(searchParams, 'place_keyword');
	const mapArea = getValueByKey(searchParams, 'map_area');
	const tournament_filter = getValueByKey(searchParams, 'tournament_filter');
	const myTournaments = getValueByKey(searchParams, 'my_tournaments');
	const managing = getValueByKey(searchParams, 'managing');
	const nowPlaying = getValueByKey(searchParams, 'now_playing');
	const nowRegistering = getValueByKey(searchParams, 'now_registering');
	const showAll = getValueByKey(searchParams, 'show_all');
	const featured = getValueByKey(searchParams, 'featured');
	let clubId = getValueByKey(searchParams, 'club_id');
	let partner = getValueByKey(searchParams, 'partner');

	if (clubId && clubIdsMapping.has(clubId.toLowerCase())) {
		partner = clubIdsMapping.get(clubId.toLowerCase()) as string;
		clubId = null;
	}

	let place: GooglePlaceResult | null = null;
	let userLocationFetch = false;
	if (!bounds && ipApi?.lat && ipApi?.lon) {
		center = {
			lat: ipApi?.lat,
			lng: ipApi?.lon
		};
	}

	if (!placeKeyword && ipApi?.city && !showAll && !keyword && !clubId) {
		placeKeyword = ipApi.city;
		place = {
			formatted_address: ipApi.city
		};
	}

	if (!bounds && center && ipApi?.lat && ipApi?.lon && !showAll && !keyword && !myTournaments && !nowPlaying && !nowRegistering && !clubId) {
		userLocationFetch = true;
	}

	return {
		...initialState,
		keyword: keyword && keyword !== '' ? keyword : null,
		bounds,
		zoomLevel: zoomLevel ? parseFloat(zoomLevel) : null,
		center,
		clubId,
		mapArea: mapArea === 'true' ? true : false,
		placeKeyword: !myTournaments ? placeKeyword : 'Anywhere',
		place: !myTournaments ? place : null,
		myTournaments: !!session?.user && !!myTournaments,
		registeredFor: !!session?.user && myTournaments === 'true' && managing !== 'true',
		managing: !!session?.user && myTournaments === 'true' && managing === 'true',
		nowRegistering: !!nowRegistering,
		nowPlaying: !!nowPlaying,
		featured: !!featured,
		userLocationFetch,
		selectedTournamentFilter: tournament_filter,
		selectedPartner: partner,
		limit: limit ? parseInt(limit) : initialState.limit,
		page: page ? parseInt(page) : initialState.page
	};
};
